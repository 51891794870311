import * as React from "react"
import { graphql } from "gatsby"
import TransitionLinkFade from "../components/TransitionLink/TransitionLinkFade"

import { BLUE } from "../constants"

import Layout from "../components/Layout/Layout"

const NotFoundPage = ({ data }) => {
  const { linkTertiary } = data.craftGeneralGlobalSet
  return (
    <Layout title="Page Not Found" theme={BLUE}>
      <div className="js-transition-link-target" data-theme={BLUE}>
        <div className="bg-blue-500 text-white px-ogs pt-16 pb-12 lg:pt-24 lg:pb-20 2xl:pt-40 2xl:pb-32 grid lg:grid-cols-12 lg:gap-x-gs">
          <div className="lg:col-span-6 lg:col-start-4 space-y-6 lg:space-y-10">
            <h2 className="text-5xl md:text-6xl font-semibold">Oops!</h2>
            <h1 className="text-xl md:text-2xl lg:text-3xl">
              We can't seem to find the page you're looking for.
            </h1>
            <div className="text-md">Here are some helpful links instead:</div>
            <ul className="space-x-4 lg:space-x-6 flex flex-wrap text-md font-semibold">
              <li>
                <TransitionLinkFade className="Link" to="/">
                  Home
                </TransitionLinkFade>
              </li>
              {linkTertiary && (
                <li>
                  <a
                    className="Link"
                    href={linkTertiary}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Contact
                  </a>
                </li>
              )}
              <li>
                <TransitionLinkFade className="Link" to="/news">
                  News
                </TransitionLinkFade>
              </li>
              <li>
                <TransitionLinkFade className="Link" to="/events">
                  Events
                </TransitionLinkFade>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    craftGeneralGlobalSet {
      linkTertiary
    }
  }
`
